if (location.hash) {
  setTimeout(function () {
    window.scrollTo(0, 0);
  }, 50);
}

$(document).ready(function () {
  $('.main-section__carrousel').owlCarousel({
    autoplay: true,
    autoplayTimeout: 4000,
    autoplaySpeed: 2000,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    // center: true,
    items: 1,
    // singleItem: true,
    // animateOut: 'fadeOut',
  });

  const scrollToContact = () => {
    const small = window.matchMedia("(max-width: 991px)");
    const isSmallScreen = small.matches;
    const offset = isSmallScreen ? -100 : -150;

    const hash = document.location.hash;
    if (hash) {
      console.log('hash', hash);
      $('html, body').animate({
        scrollTop: $(hash).offset().top + offset
      }, 2000);
    }

    $('.contact-link').on('click', (event) => {
      event.preventDefault();

      if (isSmallScreen) {
        $(".nav__toggle")[0].click();
      }

      $('html, body').animate({
        scrollTop: $('#contact').offset().top + offset
      }, 2000);
    });
  };

  const formSubmit = () => {
    $("#c_send").on('click', function (e) {
      e.preventDefault();

      if ($(this).hasClass("disabled")) {
        $("#response_email").html("Please Fill in your details correctly and try again");
      } else {

        var email = $('#c_email').val();
        var name = $('#c_name').val();
        var msg = $('#c_message').val();
        var sbj = $('#c_subject').val();

        var xmlhttp;
        if (window.XMLHttpRequest) { // code for IE7+, Firefox, Chrome, Opera, Safari
          xmlhttp = new XMLHttpRequest();
        } else { // code for IE6, IE5
          xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        }

        xmlhttp.onreadystatechange = function () {
          if (xmlhttp.readyState == 1) {
            $("#response_email").html("Sending...");
          }
          if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            $("#response_email").html(xmlhttp.responseText);
          }
        }

        xmlhttp.open("POST", "send_email.php", true);
        xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xmlhttp.send("email=" + encodeURIComponent(email) + "&name=" + encodeURIComponent(name) + "&message=" + encodeURIComponent(msg) + "&subject=" + encodeURIComponent(sbj));
        return false;
      }
    });
  };

  const validateForm = () => {
    $("#c_name").on('keyup', function () {
      var name = $("#c_name").val();
      if (name == null || name == "") {
        $("#c_name").removeClass("green");
      } else {
        $("#c_name").addClass("green");
      }
      enable_form();
    });

    $("#c_email").on('keyup', function () {
      var email = $("#c_email").val();
      var atpos = email.indexOf("@");
      var dotpos = email.lastIndexOf(".");

      if (email == null || email == "" || atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
        $("#c_email").removeClass("green");
      } else {
        $("#c_email").addClass("green");
      }
      enable_form();
    });


    $("#c_message").on('keyup', function () {
      var message = $("#c_message").val();
      if (message == null || message == "" || message.length < 9) {
        $("#c_message").removeClass("green");
      } else {
        $("#c_message").addClass("green");
      }

      enable_form();

    });

    $("#c_subject").on('keyup', function () {
      var subject = $("#c_subject").val();
      if (subject == null || subject == "") {
        $("#c_subject").removeClass("green");
      } else {
        $("#c_subject").addClass("green");
      }

      enable_form();
    });

  };

  formSubmit();
  validateForm();
  scrollToContact();
});

function enable_form() {
  if (
    $("#c_name").hasClass("green") &&
    $("#c_email").hasClass("green") &&
    $("#c_message").hasClass("green") &&
    $("#c_subject").hasClass("green")
  ) {
    $("#c_send").removeClass("disabled");
  } else {
    $('#c_send').addClass("disabled");
  }
}